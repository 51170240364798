import React, { useState, useEffect } from "react";
import Table from "./table.js"; // Import the Table component
import useColumns from "./utilities/columns.js"; // Import the useColumns custom hook
import { createClient } from "@supabase/supabase-js"; // Import the createClient function from Supabase JS
import { getTokens, getTokenValue } from "./utilities/general"; // Import utility functions

function App() {
  const columns = useColumns(); // Retrieve columns using the useColumns hook
  const [data, setData] = useState([]); // State variable to store the fetched data
  const [supabase, setSupabase] = useState(null); // State variable to store the Supabase client instance

  useEffect(() => {
    // useEffect hook to fetch Supabase URL and role tokens
    getTokens(function (data) {
      const supabaseUrl = getTokenValue("supabase_url", data); // Extract Supabase URL from tokens
      const supabaseRole = getTokenValue("supabase_role", data); // Extract Supabase role from tokens

      // Create the Supabase client instance using the URL, role, and options
      setSupabase(
        createClient(supabaseUrl, supabaseRole, { persistSession: false })
      );
    });
  }, []);

  useEffect(() => {
    // useEffect hook to fetch data when the Supabase client is available
    if (supabase !== null) {
      fetchData();
    }
  }, [supabase]);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <main className="mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <div className="mt-4">
          {/* Render the Table component if the Supabase client is available */}
          {supabase !== null && <Table columns={columns} data={data} />}
        </div>
      </main>
    </div>
  );

  async function fetchData() {
    // Fetch data from the 'guests' table using the Supabase client
    const { data, error } = await supabase
      .from("guests")
      .select(
        `id,
        email,
        guestFirstName,
        guestLastName,
        guestPhoneNumber,
        guestJobTitle,
        company,
        guestNationality,
        guestIdNo,
        guestDateOfBirth,
        created_at,
        updated_at,
        guestIdIssueDate,
        guestIdExpiryDate,
        guestCompanyCountry,
        guestIsStaff,
        arrivalTransportationMethod,
        arrivalFlightNumber,
        arrivalAirport,
        arrivalAirportTerminal,
        carRegistrationNumber,
        arrivalDate,
        arrivalFrom,
        departureTransportationMethod,
        departureFlightNumber,
        departureAirport,
        departureAirportTerminal,
        departureDate,
        departureTo,
        accomodationRequired,
        accomodationCheckIn,
        accomodationCheckOut,
        accomodationComments,
        attendDinner,
        dietaryRequirements,
        arrivalTime,
        departureTime,
        gdprConsent,
        company (companyName)`
      );

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setData(data); // Store the fetched data in the state variable
    }
  }
}

export default App;
