import { useMemo } from "react";
import { format } from "date-fns";

export default function useColumns() {
  const columns = useMemo(
    () => [
      {
        // first group
        Header: "Guest",
        // First group columns
        columns: [
          {
            Header: "First Name",
            accessor: "guestFirstName",
            Cell: (row) => <div className="w-32">{row.value}</div>,
          },
          {
            Header: "Last Name",
            accessor: "guestLastName",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Phone",
            accessor: "guestPhoneNumber",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "Job title",
            accessor: "guestJobTitle",
          },
          {
            Header: "Company",
            accessor: "company.companyName",
          },
          {
            Header: "Is Staff?",
            accessor: "guestIsStaff",
            Cell: (row) => {
              return row.value === true ? (
                <div style={{ textAlign: "center" }}>1</div>
              ) : row.value === false ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}></div>
              );
            },
          },
          {
            Header: "Nationality",
            accessor: "guestNationality",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "ID Number",
            accessor: "guestIdNo",
          },
          {
            Header: "ID Issue Date",
            accessor: "guestIdIssueDate",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
          {
            Header: "ID Expiry Date",
            accessor: "guestIdExpiryDate",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
          {
            Header: "DOB",
            accessor: "guestDateOfBirth",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
        ],
      },
      {
        // third group
        Header: "Travel Details",
        // third group columns
        columns: [
          {
            Header: "Arrival Date",
            accessor: "arrivalDate",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
          {
            Header: "Arrival Time",
            accessor: "arrivalTime",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "Arrival Transportation",
            accessor: "arrivalTransportationMethod",
          },
          {
            Header: "Car Registration",
            accessor: "carRegistrationNumber",
          },
          {
            Header: "Arrival Flight No",
            accessor: "arrivalFlightNumber",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "Arrival Airport",
            accessor: "arrivalAirport",
          },
          {
            Header: "Arrival Terminal",
            accessor: "arrivalAirportTerminal",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "Arriving From",
            accessor: "arrivalFrom",
          },
          {
            Header: "Departure Date",
            accessor: "departureDate",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
          {
            Header: "Departure Time",
            accessor: "departureTime",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "Departure Transportation",
            accessor: "departureTransportationMethod",
          },
          {
            Header: "Departure Flight No",
            accessor: "departureFlightNumber",
          },
          {
            Header: "Departure Airport",
            accessor: "departureAirport",
          },
          {
            Header: "Departure Terminal",
            accessor: "departureAirportTerminal",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
          },
          {
            Header: "Departing To",
            accessor: "departureTo",
          },
        ],
      },
      {
        // fourth group
        Header: "Accomodation & Dinner",
        // fourth group columns
        columns: [
          {
            Header: "Accomodation Required",
            accessor: "accomodationRequired",
            //render boolean value
            Cell: (row) => {
              return row.value === true ? (
                <div style={{ textAlign: "center" }}>1</div>
              ) : row.value === false ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}></div>
              );
            },
          },
          {
            Header: "Accomodation Check-in",
            accessor: "accomodationCheckIn",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
          {
            Header: "Accomodation Check-out",
            accessor: "accomodationCheckOut",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy")}
                </div>
              );
            },
          },
          {
            Header: "Accomodation Comments",
            accessor: "accomodationComments",
          },
          {
            Header: "Attending Dinner",
            accessor: "attendDinner",
            //render boolean value
            Cell: (row) => {
              return row.value === true ? (
                <div style={{ textAlign: "center" }}>1</div>
              ) : row.value === false ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}></div>
              );
            },
          },
          {
            Header: "Dietary Requirements",
            accessor: "dietaryRequirements",
          },
        ],
      },
      {
        // first group
        Header: "Meta",
        // First group columns
        columns: [
          {
            Header: "Created at",
            accessor: "created_at",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy HH:mm")}
                </div>
              );
            },
          },
          {
            Header: "Updated at",
            accessor: "updated_at",
            Cell: (row) => {
              return row.value === null ? (
                <div style={{ textAlign: "center" }}></div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {format(new Date(row.value), "dd/MM/yyyy HH:mm")}
                </div>
              );
            },
          },
        ],
      },
    ],
    []
  );

  return columns;
}
