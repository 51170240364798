// Send a POST request to retrieve tokens
export const getTokens = (callback) => {
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", "https://ignitionassets.com/booking/php/controller.php", true);
  
    // Define a callback function to handle the response
    xhttp.onreadystatechange = function () {
      if (xhttp.readyState === XMLHttpRequest.DONE) {
        // Parse the response text as JSON and pass it to the callback function
        callback(JSON.parse(xhttp.responseText));
      }
    };
  
    var formData = new FormData();
    formData.append("tokens", "all");
  
    // Send the request
    xhttp.send(formData);
  };
  
  // Get the value of a specific token
  export const getTokenValue = (key, tokens) => {
    var value = null;
    var i;
  
    // Iterate over the tokens array
    for (i = 0; i < tokens.length; i++) {
      // Check if the key matches the current token's name
      if (key === tokens[i].name) {
        value = tokens[i].value; // Set the value to the matched token's value
      }
    }
  
    return value; // Return the value (or null if no match was found)
  };
  